<template>
  <div class="modal-content">
    <div class="modal-header eval-card-group-header">
      <h5 class="modal-title eval-black-font" id="removeClientModalLabel">Remover sistema</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body eval-body eval-black-font">
      <div class="container">
        <div class="row">
          <div class="col-1 col-md-2 eval-modal-row-padding">
            <img
              class="eval-modal-img"
              src="../assets/icone-remover-sistema.png"
              width="80"
              height="80"
              alt=""
            />
          </div>
          <div class="col-11 col-md-10">
            <p class="eval-modal-p">Ao pressionar o botão 'Continuar' o sistema selecionado não mais poderá fazer uso de suas chaves de assinatura.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer eval-body eval-black-font">
      <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="$emit('remove-client', client)" >Continuar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: Number
  },
};
</script>