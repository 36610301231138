<template>
  <div class="modal-content">
    <div class="modal-header eval-card-group-header">
      <h5 class="modal-title eval-black-font" id="addClientModalLabel">Adicionar sistema</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body eval-body eval-black-font">
      <div class="container">
        <div class="row">
          <div class="col-1 col-md-2 eval-modal-row-padding">
            <img
              class="eval-modal-img"
              src="../assets/icone-adicionar-sistema.png"
              width="80"
              height="80"
              alt=""
            />
          </div>
          <div class="col-11 col-md-10">
            <p class="eval-modal-p">Selecione o(s) sistema(s) que poderão fazer uso de suas chaves de assinatura e pressione o botão 'Continuar'.</p>
          </div>
        </div>
        <div class="row">
          <div class="input-group mb-3">
            <select multiple="" class="form-control" id="select" v-model="selected">
              <option v-for="option in clients" :key="option.id" :value="option.id">
                {{ option.displayName }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <p>
            <em>Para selecionar múltiplos sistemas mantenha a tecla <kbd>Ctrl</kbd> pressionada enquanto realiza a seleção.</em>
          </p>
        </div>
      </div>
    </div>
    <div class="modal-footer eval-body eval-black-font">
      <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-client', selected)">Continuar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clients: Array,
  },
  data() {
    return {
      selected: [],
    };
  },
};
</script>