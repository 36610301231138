<template>
  <div class="modal-content" @keydown.esc="lifetimeInDays = ''">
    <div class="modal-header eval-card-group-header">
      <h5 class="modal-title eval-black-font" id="authorizeModalLabel">
        Autorizar uso do {{ provider.name }}
      </h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        @click="lifetimeInDays = ''"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body eval-body eval-black-font">
      <div class="container">
        <div class="row">
          <div class="col-1 col-md-2 eval-modal-row-padding">
            <img 
              class="eval-modal-img"
              src="../assets/icone-autorizar-uso.png"
              width="80"
              height="80"
              alt=""
            />
          </div>
          <div class="col-11 col-md-10">
            <p class="eval-modal-p">Informe o tempo de duração (em dias) para essa autorização ou deixe em branco para usarmos o período máximo disponível.</p>
          </div>
        </div>
        <div class="row">
          <div class="input-group mb-3">
            <input type="number" class="form-control" aria-label="Tempo de duração" v-model="lifetimeInDays"/>
          </div>
        </div>
        <div class="row">
          <p>
            <em>Ao pressionar o botão 'Continuar' você será redirecionado para o site da {{ provider.name }} para realizar o processo de autorização.</em>
          </p>
        </div>
      </div>
    </div>
    <div class="modal-footer eval-body eval-black-font">
      <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('authorize-provider', provider.id, lifetimeInDays)" @click="lifetimeInDays = ''">Continuar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    provider: Object,
  },
  data() {
    return {
      lifetimeInDays: ""
    }
  }
};
</script>