async function sendRequest(url, method, token, payload) {
  const init = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`,
    },
    body: payload
  };

  return await fetch(new Request(url, init));
}

async function postRequest(url, token, data) {
  return sendRequest(url, 'POST', token, data);
}

async function getRequest(url, token) {
  return sendRequest(url, 'GET', token, null);
}

async function putRequest(url, token, data) {
  return sendRequest(url, 'PUT', token, data);
}

async function deleteRequest(url, token) {
  return sendRequest(url, 'DELETE', token, null);
}

export { postRequest, getRequest, putRequest, deleteRequest };
