<template>
  <div>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="authorizeModal" tabindex="-1" role="dialog" aria-labelledby="authorizeModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <AuthorizeModal v-if="authorizeModalVisible" @close="authorizeModalVisible = false" :provider="authorizeModalData" v-on:authorize-provider="authorizeProvider"></AuthorizeModal>
      </div>
    </div>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="revokeModal" tabindex="-1" role="dialog" aria-labelledby="revokeModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <RevokeModal v-if="revokeModalVisible" @close="revokeModalVisible = false" :provider="revokeModalData" v-on:revoke-provider="revokeProviderAuth"></RevokeModal>
      </div>
    </div>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="addClientModal" tabindex="-1" role="dialog" aria-labelledby="addClientModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <AddClientModal v-if="addClientModalVisible" @close="addClientModalVisible = false" :clients="addClientModalData" v-on:add-client="handleAddClient"></AddClientModal>
      </div>
    </div>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="removeClientModal" tabindex="-1" role="dialog" aria-labelledby="removeClientModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <RemoveClientModal v-if="removeClientModalVisible" @close="removeClientModalVisible = false" :client="removeClientModalData" v-on:remove-client="handleRemoveClient"></RemoveClientModal>
      </div>
    </div>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="updateProfileModal" tabindex="-1" role="dialog" aria-labelledby="updateProfileModal" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <UpdateProfileModal v-if="updateProfileModalVisible" :profile="updateProfileModalData" v-on:update-profile="handleUpdateProfile"></UpdateProfileModal>
      </div>
    </div>
    <nav class="navbar navbar-expand-md navbar-dark bg-primary" id="my-nav">
      <div class="container-fluid">
        <div class="navbar-brand text-primary eval-navbar">
          <img class="img-fluid d-block" src="../assets/logo-e.png" alt="MADICS Cloud" height="60px" width="60px">
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3 class="text-uppercase text-white">MADICS Cloud</h3>
          </div>
        </div>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right class="mr-auto eval-navbar-button">
              <template #button-content>
                <strong v-if="$keycloak.tokenParsed.document_number.length == 11">{{ $keycloak.fullName }}</strong>
                <strong v-if="$keycloak.tokenParsed.document_number.length == 14">{{ $keycloak.tokenParsed.given_name }}</strong>
              </template>
              <b-dropdown-item href="#" data-toggle="modal" data-target="#updateProfileModal" @click="openUpdateProfileModal()">Perfil</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item href="#" @click="$keycloak.logoutFn">Sair</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </nav>
    <div class="container mt-3 eval-card-container shadow">
      <div class="row eval-card-group">
        <b-card no-body header-tag="header" class="eval-card-group-header">
          <template #header>
            <div class="container">
              <div class="row">
                <div class="col-3 col-sm-2 col-xl-1">
                  <a noref="" title="Aqui você controla as suas autorizações junto aos provedores de serviços de criptografia (PSC).">
                    <img class="rounded border-0" src="../assets/icone-minhas-autorizacoes.png" width="60" height="60" alt="">
                  </a>
                </div>
                <div class="col-9 col-sm-10 col-xl-11 eval-col">
                  <h2 class="eval-h2-font">Minhas autorizações</h2>
                </div>
              </div>
            </div>
          </template>
          <b-card-group>
            <b-card v-for="provider in providers" :key="provider.id" class="eval-body">
              <div class="container">
                <div class="row justify-content-center">
                  <img class="card-img-top eval-provider-card-image" :src="getImgUrl(provider.imageName)" :alt="provider.name">
                </div>
                <div class="card-body eval-padding">
                  <h4 class="card-title text-uppercase text-center eval-font">{{ provider.name }}</h4>
                  <p v-if="provider.expiresAt != null" class="card-text text-center eval-font">Válida até {{ new Date(provider.expiresAt+"Z").toLocaleString() }}</p>
                  <p v-else class="card-text text-center eval-font">Clique em autorizar</p>
                  <button type="button" class="btn btn-primary btn-block text-center eval-font" data-toggle="modal" data-target="#authorizeModal" @click="openAuthorizeModal(provider)">Autorizar</button>
                  <button type="button" class="btn btn-danger btn-block text-center eval-font" data-toggle="modal" data-target="#revokeModal" @click="openRevokeModal(provider)" :disabled="provider.expiresAt == null">Cancelar</button>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </b-card>
      </div>
    </div>
    <div class="container mt-3 eval-card-container shadow">
      <div class="row eval-card-group">
        <b-card no-body header-tag="header" class="eval-card-group-header">
          <template #header>
            <div class="container">
              <div class="row">
                <div class="col-3 col-sm-2 col-xl-1">
                  <a noref="" title="Aqui você controla os sistemas que podem fazer uso de suas chaves de assinatura.">
                    <img class="rounded border-0" src="../assets/icone-meus-sistemas.png" width="60" height="60" alt="">
                  </a>
                </div>
                <div class="col-9 col-sm-10 col-xl-11 eval-col">
                  <h2 class="eval-h2-font">Meus sistemas</h2>
                </div>
              </div>
            </div>
          </template>
          <b-card-group>
            <b-card class="eval-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table eval-black-font eval-table-font" aria-describedby="Lista de sistemas autorizados">
                      <thead> 
                        <tr class="w-100">
                          <th scope="col">#</th>
                          <th class="w-50 eval-black-font" scope="col">Sistema</th>
                          <th class="w-50 eval-black-font" scope="col">Autorizado em</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(client, index) in authorizedClients" :key="client.id">
                          <td class="eval-black-font">{{ index + 1 }}</td>
                          <td class="eval-black-font">{{ client.displayName }}</td>
                          <td class="eval-black-font">{{ new Date(client.createdAt+"Z").toLocaleString() }}</td>
                          <td class="eval-black-font"><button type="button" class="btn btn-danger" data-toggle="modal" data-target="#removeClientModal" @click="openRemoveClientModal(client.id)">X</button></td>
                        </tr>
                        <tr v-if="authorizedClients.length == 0" role="row" class="b-table-empty-row">
                          <td colspan="4" role="cell" class="eval-black-font">
                            <div>
                              <div role="alert" aria-live="polite">
                                <div class="text-center my-2">
                                  Nenhum sistema autorizado.
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" class="btn btn-primary text-center" data-toggle="modal" data-target="#addClientModal" @click="openAddClientModal">Adicionar sistema</button>
                  </div>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </b-card>
      </div>
    </div>
    <div class="container mt-3 mb-3 eval-card-container shadow">
      <div class="row eval-card-group">
        <b-card no-body header-tag="header" class="eval-card-group-header">
          <template #header>
            <div class="container">
              <div class="row">
                <div class="col-3 col-sm-2 col-xl-1">
                  <a noref="" title="Aqui você visualiza o histórico de suas operações.">
                    <img class="rounded border-0" src="../assets/icone-historico-de-operacoes.png" width="60" height="60" alt="">
                  </a>
                </div>
                <div class="col-9 col-sm-10 col-xl-11 eval-col">
                  <h2 class="eval-h2-font">Histórico de operações</h2>
                </div>
              </div>
            </div>
          </template>
          <b-card-group>
            <b-card class="eval-body">
              <div class="row">
            <div class="col-md-12">
              <b-table
                class="eval-black-font"
                :busy="isLogsTableBusy"
                show-empty 
                small
                stacked="md"
                empty-text="Não há registros."
                :items="logEntries"
                :fields="logEntriesFields"
                :current-page="logEntriesCurrentPage"
                :per-page="logEntriesRowsPerPage"
                :sort-by.sync="logEntriesSortBy"
                :sort-desc.sync="logEntriesSortDesc"
                sort-icon-left>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Carregando...</strong>
                </div>
              </template>
              <template #cell(#)="data">
                {{ (data.index + 1) + (logEntriesRowsPerPage * (logEntriesCurrentPage-1)) }}
              </template>
              </b-table>
              <b-pagination
                v-model="logEntriesCurrentPage"
                :total-rows="logEntriesTotalRows"
                :per-page="logEntriesRowsPerPage"
                align="center"
                size="md">
              </b-pagination>
            </div>
          </div>
            </b-card>
          </b-card-group>
        </b-card>
      </div>
    </div>
    <footer class="footer text-center text-white bg-primary pt-5 pb-2">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="text-left">
              <img class="eval-footer-img" src="../assets/logo-eval-branco-verde.png" alt="E-VAL Saúde" width="125px">
            </div>
            <div class="text-left">
              <p>Rua Paulistânia, n° 381, 2º Andar, Sumarezinho<br />São Paulo – SP, CEP: 05440-000<br/>Telefone (11) 3670-3825<br />e-mail: <a class="text-white" href="mailto:contato@eval.digital">contato@eval.digital</a><br /><a class="text-white" href="https://www.eval.digital">Saiba mais sobre nós</a></p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div>© 2004-2023 EVAL Tecnologia em Informática. Todos os direitos reservados.</div>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import Vuelidate from 'vuelidate'
import AuthorizeModal from './AuthorizeModal';
import RevokeModal from './RevokeModal';
import AddClientModal from './AddClientModal';
import RemoveClientModal from './RemoveClientModal';
import UpdateProfileModal from './UpdateProfileModal';
import { postRequest, getRequest, deleteRequest, putRequest } from '../api/http';

Vue.use(Vuelidate);

var keycloak = Vue.prototype.$keycloak;
const USER_BASE_URL = window.location.origin + "/psc-hub-admin/v1/users/";
const OAUTH_URL = window.location.origin + "/psc-hub/v1/oauth/users/";
const KEYCLOAK_BASE_URL = window.location.origin + "/psc-hub-admin/v1/kc/";

export default {
  name: "Main",

  components: {
    AuthorizeModal,
    RevokeModal,
    AddClientModal,
    RemoveClientModal,
    UpdateProfileModal
  },

  data() {
    return {
      // Main page
      providers: [],
      authorizedClients: [],
      logEntries: [],
      logEntriesFields: [
          '#',
          { 
            key: 'ts', 
            sortable: true, 
            label: 'Data', 
            formatter: (value, key, item) => {
              return new Date(item.ts+"Z").toLocaleString();
            } 
          },
          { 
            key: 'c', 
            sortable: true, 
            label: 'Sistema' 
          },          
          { 
            key: 'r', 
            sortable: true, 
            label: 'Recurso',
            formatter: (value, key, item) => {
              let data = ["Reservado", "Sistemas", "Autorizações dos sistemas", "Provedores", "Usuários", "Sistemas do usuário", "Autorizações do usuário", "Certificados", "Assinaturas"][item.r];
              return (data === undefined ? "?" : data);
            }
          },
          { 
            key: 'o', 
            sortable: true, 
            label: 'Operação',
            formatter: (value, key, item) => {
              let data = ["Consulta", "Criação", "Modificação", "Remoção"][item.o];
              return (data === undefined ? "?" : data);
            }
          },
          { 
            key: 's', 
            sortable: true, 
            label: 'Resultado' 
          },
          { 
            key: 'x', 
            sortable: true, 
            label: 'Detalhes' 
          },
      ],
      logEntriesSortBy: 'timestamp',
      logEntriesSortDesc: true,
      logEntriesCurrentPage: 1,
      logEntriesRowsPerPage: 10,
      logEntriesTotalRows: 0,
      isLogsTableBusy: false,

      // Modals
      authorizeModalVisible: false,
      authorizeModalData: null,
      revokeModalVisible: false,
      revokeModalData: null,
      addClientModalVisible: false,
      addClientModalData: null,
      removeClientModalVisible : false,
      removeClientModalData: null,
      updateProfileModalVisible: false,
      updateProfileModalData: null,
    };
  },

  mounted() {
    this.initialize();
    this.handleRedirect();
    keycloak.keycloak.updateToken(-1)
    .catch(function() {
        // eslint-disable-next-line no-console
      console.log('Failed to refresh the token, or the session has expired');
    });
  },

  methods: {

    // Alerts
    showAlert(variant, alertMessage) {
      this.$bvToast.toast(alertMessage, {
          title: ' ',
          autoHideDelay: 3000,
          appendToast: true,
          variant: variant,
          toaster: 'b-toaster-top-center'
        });
    },

    showInfoAlert(alertMessage) {
      this.showAlert("success", alertMessage);
    },

    showWarningAlertWithStatus(alertMessage, status) {
      this.showAlert("danger", alertMessage + ` Código de erro ${status}`);
    },

    showWarningAlert(alertMessage) {
      this.showAlert("danger", alertMessage);
    },

    // Authorize
    openAuthorizeModal: function(provider) {
        this.authorizeModalData = provider;
        this.authorizeModalVisible = true;
    },

    authorizeProvider: function(providerId, lifetimeInDays) {
      const days = lifetimeInDays ? lifetimeInDays : keycloak.tokenParsed.document_number.length > 11 ? '30' : '7';
      const url = OAUTH_URL + keycloak.tokenParsed.preferred_username + "/providers/" + providerId + "/" + days;

      postRequest(url, keycloak.token)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          this.showWarningAlertWithStatus("Falha ao autorizar provedor.", response.status);
        }
      })
      .then(result => window.location.href = decodeURIComponent(result.data));
    },

    // Revoke Psc Authorization
    openRevokeModal: function(provider) {
      this.revokeModalData = provider;
      this.revokeModalVisible = true;
    },

    revokeProviderAuth: function (providerId) {
      const url = OAUTH_URL + keycloak.tokenParsed.preferred_username + "/providers/" + providerId;

      deleteRequest(url, keycloak.token)
      .then(response => {
        if (response.ok) {
          this.showInfoAlert("Autorização cancelada com sucesso.");
          this.loadUserData();
        } else {
          this.showWarningAlertWithStatus("Falha ao cancelar autorização no provedor.", response.status);
        }
      });
    },

    // Add Client
    openAddClientModal: async function() {
        await this.getAvailableClients();
        this.addClientModalVisible = true;
    },

    handleAddClient: function(selected) {
      selected.forEach(this.addClient);
    },

    addClient: function (element) {
      const url = USER_BASE_URL + keycloak.tokenParsed.preferred_username + "/clients/" + element;

      postRequest(url, keycloak.token)
      .then(response => {
        if (response.ok) {
          this.showInfoAlert("Sistema adicionado com sucesso.");
          this.loadUserData();
        } else {
          this.showWarningAlertWithStatus("Falha ao adicionar sistema.", response.status);
        }
      });
    },

    // Remove Client
    openRemoveClientModal: function(client) {
      this.removeClientModalData = client;
      this.removeClientModalVisible = true;
    },

    handleRemoveClient: function (client) {
      this.removeClient(client);
      this.getAvailableClients();
    },

    removeClient: function (client) {
      const url = USER_BASE_URL + keycloak.tokenParsed.preferred_username + "/clients/" + client;
      
      deleteRequest(url, keycloak.token)
      .then(response => {
        if (response.ok) {
          this.showInfoAlert("Sistema removido com sucesso.");
          this.loadUserData();
        } else {
          this.showWarningAlertWithStatus("Falha ao remover sistema.", response.status);
        }
      });
    },

    getAvailableClients: function () {
      const availableClientsURL = USER_BASE_URL + keycloak.tokenParsed.preferred_username + "/available_clients";

      getRequest(availableClientsURL, keycloak.token)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          this.showWarningAlertWithStatus("Falha ao obter lista de sistemas disponíveis.", response.status);
        }
      })      
      .then(result=> this.addClientModalData = result.data);
    },

    getImgUrl: function(name) {
      return require('../assets/'+ name +'.png')
    },

    handleRedirect: function () {
      var GET = {};
      var query = window.location.search.substring(1).split("&");
      var max = query.length;
      var code = "";
      var error = "";
      var state = "";
      var url = "";

      if (max <= 1) {
        return;
      }
      for (var i = 0; i < max; i++) {
        if (query[i] === "")
          // check for trailing & with no param
          continue;

        var param = query[i].split("=");
        GET[decodeURIComponent(param[0])] = decodeURIComponent(param[1] || "");
      }
      code = GET.code;
      error = GET.error;
      state = GET.state;

      if (error == "" || error == undefined) {
        url =
          window.location.origin +
          "/psc-hub/v1/oauth/auth_redirect?code=" +
          code +
          "&state=" +
          state;
        this.authRedirect(url);
        history.replaceState(null, '', window.origin + "/main");
      } else {
        url =
          window.location.origin +
          "/psc-hub/v1/oauth/auth_redirect?error=" +
          error +
          "&state=" +
          state;
        this.authRedirect(url);
        history.replaceState(null, '', window.origin + "/main");
      }
    },

    authRedirect: function (url) {
      getRequest(url, keycloak.token)
      .then(response => {
        if (response.ok) {
          this.getUserPscsList();
        } else {
          this.showWarningAlertWithStatus("Falha ao autorizar.", response.status);
        }
      });
    },

    initialize: function() {
      const url = USER_BASE_URL + keycloak.tokenParsed.preferred_username;

      getRequest(url, keycloak.token)
      .then(response => Promise.all([response.status, response.json()]))
      .then(([status, result]) => {
        switch(status) {
          case 200:
            if(result.documentNumber == keycloak.tokenParsed.document_number) {
              this.loadUserData();
            } else {
              this.updateUser(keycloak.tokenParsed.document_number);
            }
          break;
          case 404:
              this.addUser(keycloak.tokenParsed.document_number);
          break;
          default:
            // eslint-disable-next-line no-console
            console.log(status);
          break;
        }
      });
    },

    loadUserData: function() {
      Promise.all([this.getUserPscsList(), this.getUserClientsList(), this.getUserAudit()]);
    },

    // PSCS
    getUserPscsList: function () {
      const url = USER_BASE_URL + keycloak.tokenParsed.preferred_username + "/tokens";
      
      getRequest(url, keycloak.token)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
           this.showWarningAlertWithStatus("Falha ao obter lista de provedores.", response.status);
        }
      })     
      .then(result => this.providers = result.data);
    },
    
    // Clients
    toggleClientsTableStatus: function() {
        this.clientsTableIsBusy = !this.clientsTableIsBusy;
    },
    
    getUserClientsList: function () {
      this.toggleClientsTableStatus();
      const url = USER_BASE_URL + keycloak.tokenParsed.preferred_username + "/clients";

      getRequest(url, keycloak.token)
      .then(response => {
        if (response.ok) {
         return response.json();
        } else {
          this.showWarningAlertWithStatus("Falha ao obter lista de sistemas disponíveis.", response.status);
        }
      })
      .then(result => {
          this.authorizedClients = result.data;
      });
      this.toggleClientsTableStatus();
    },
    
    toggleLogsTableStatus: function() {
        this.isLogsTableBusy = !this.isLogsTableBusy;
    },

    // Audit
    getUserAudit: function () {
      this.toggleLogsTableStatus();
      const url = USER_BASE_URL + keycloak.tokenParsed.preferred_username + "/logs";

      getRequest(url, keycloak.token)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          this.showWarningAlertWithStatus("Falha ao obter histórico de operações.", response.status);
        }
      })
      .then(result => {
          this.logEntries = this.filteredOperations = result.data;
          this.logEntriesTotalRows = this.logEntries.length;
      });
      this.toggleLogsTableStatus();
    },

    addUser: function(documentNumber) {
      const body = JSON.stringify({ 'name': keycloak.tokenParsed.preferred_username, 'documentNumber': documentNumber, 'terms': true});
      
      postRequest(USER_BASE_URL, keycloak.token, body)
      .then(response => {
        if (response.ok) {
          this.loadUserData();
        }
      })
    },

    updateUser: function(documentNumber) {
      const url = USER_BASE_URL + keycloak.tokenParsed.preferred_username;
      const body = JSON.stringify({ 'name': keycloak.tokenParsed.preferred_username, 'documentNumber': documentNumber, 'terms': true});
      putRequest(url, keycloak.token, body)
      .then(response => {
        if (response.ok) {
          this.loadUserData();
        }
      })
    },

    openUpdateProfileModal: function() {
      const data = {
        username: keycloak.idTokenParsed.email,
        givenName: keycloak.idTokenParsed.given_name,
        familyName: keycloak.idTokenParsed.family_name,
        documentNumber: keycloak.idTokenParsed.document_number
      };

      this.updateProfileModalData = data;
      this.updateProfileModalVisible = true;
    },

    handleUpdateProfile: function(updatedProfile) {
      
      if(updatedProfile.newPassword === undefined) {
        // Atualização dos dados do usuário.
        const url = KEYCLOAK_BASE_URL + keycloak.tokenParsed.sub + "/profile";
        const body = JSON.stringify({
          'firstName': updatedProfile.givenName,
          'lastName': updatedProfile.familyName,
          'attributes': {
            'cpfCnpj':updatedProfile.documentNumber,
            'termos': true
          }
        });

        putRequest(url, keycloak.token, body)
        .then(response => {
          if (response.ok) {
             this.showInfoAlert('Dados atualizados com sucesso.');
             this.$keycloak.logoutFn();
          } else {
             this.showWarningAlertWithStatus('Falha ao atualizar dados.', response.status);
          }
        });
      } else {
        // Atualização da senha do usuário.
        const url = KEYCLOAK_BASE_URL + keycloak.tokenParsed.sub + "/password";
        const body = JSON.stringify({ 'password': updatedProfile.newPassword });

        putRequest(url, keycloak.token, body)
        .then(response => {
          if (response.ok) {
            this.showInfoAlert('Senha atualizada com sucesso.');
            this.$keycloak.logoutFn();            
          } else {
            if (response.status === 400) {
              this.showWarningAlert('A senha deve ser diferente das últimas 3 cadastradas.');
            } else {
              this.showWarningAlertWithStatus('Falha ao atualizar senha.', response.status);
            }
          }
        });
      }
    }
  }
};
</script>
