<template>
  <div class="modal-content" ref="updateModal">
    <div class="modal-header eval-card-group-header">
      <h5 class="modal-title eval-black-font" id="updateProfileModalLabel">Editar conta</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        @click="close"
        ref="closeButton">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body eval-body eval-black-font">
      <b-tabs v-model="tabIndex" vertical>
        <b-tab title="Perfil" v-on:click="setActive(0)">
          <b-form>
            <div class="row mb-3">
              <div class="col-md-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>E-mail</b-input-group-text>
                  </template>
                  <b-form-input disabled :value=profile.username />
                </b-input-group>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text id="givenNameLabel">Primeiro nome</b-input-group-text>
                  </template>
                  <b-form-input :value=profile.givenName v-model="updatedProfile.givenName" />
                </b-input-group>
              </div>
            </div>
            <div class="row mb-3" id="familyNameGroup">
              <div class="col-md-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>Sobrenome</b-input-group-text>
                  </template>
                  <b-form-input :value=profile.familyName v-model="updatedProfile.familyName" />
                </b-input-group>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text id="cpfCnpjLabel">CPF</b-input-group-text>
                  </template>
                  <b-form-input id="cpfCnpj" type="text" placeholder="99999999999" :value=profile.documentNumber v-model="updatedProfile.documentNumber" />
                </b-input-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-form-checkbox id="terms" v-model="checked">Aceito os <a href="https://cloud.madics.com.br/eula" target="_blank" rel="noopener"> termos e condições do serviço.</a></b-form-checkbox>
              </div>
            </div>
          </b-form>
        </b-tab>
        <b-tab title="Senha" v-on:click="setActive(1)">
          <b-form>
            <div class="row mb-3">
              <div class="col-md-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>Nova senha</b-input-group-text>
                  </template>
                  <b-form-input type="password" v-model="updatedProfile.newPassword"/>
                </b-input-group>
              </div>
            </div>
            <div class="text-danger" v-show="$v.updatedProfile.newPassword.$error">
              <div v-show="updatedProfile.newPassword && !$v.updatedProfile.newPassword.valid">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <small >A senha deve conter pelo menos:</small>
                  </div>
                </div>
              </div>
              <div v-show="updatedProfile.newPassword && !$v.updatedProfile.newPassword.minLength">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <small class="card-text">8 caracteres</small>
                  </div>
                </div>
              </div>
              <div v-show="updatedProfile.newPassword && !$v.updatedProfile.newPassword.containsUpperCase">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <small >Uma letra maiúscula</small>
                  </div>
                </div>
              </div>
              <div v-show="updatedProfile.newPassword && !$v.updatedProfile.newPassword.containsLowercase">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <small >Uma letra minúscula</small>
                  </div>
                </div>
              </div>
              <div v-show="updatedProfile.newPassword && !$v.updatedProfile.newPassword.containsNumber">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <small >Um número</small>
                  </div>
                </div>
              </div>
              <div v-show="updatedProfile.newPassword && !$v.updatedProfile.newPassword.containsSpecial">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <small >Um caractere especial</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>Confirmação</b-input-group-text>
                  </template>
                  <b-form-input type="password" v-model="updatedProfile.newPasswordConfirmation"/>
                </b-input-group>
              </div>
            </div>
            <div class="text-danger" v-show="$v.updatedProfile.newPasswordConfirmation.$error">
              <div class="row mb-3">
                <div class="col-md-12">
                  <small v-if="updatedProfile.newPasswordConfirmation && !$v.updatedProfile.newPasswordConfirmation.sameAsPassword">As senhas devem ser idênticas</small>
                </div>
              </div>
            </div>
          </b-form>
        </b-tab>
      </b-tabs>
    </div>
    <div class="modal-footer eval-body eval-black-font">
      <button type="button" v-bind:class="{'btn btn-light': this.isDisabled, 'btn btn-primary': !this.isDisabled }" data-dismiss="modal" v-on:click="closeAndEmit(updatedProfile)" :disabled="this.isDisabled">Salvar</button>
    </div>
  </div>
</template>

<script>
import { required, requiredIf, sameAs, minLength, numeric } from 'vuelidate/lib/validators'

export default {
  props: {
    profile: Object,
  },
  data() {
    return {
      tabIndex: 0,
      checked: true,
      updatedProfile: {
        givenName: this.profile.givenName,
        familyName: this.profile.familyName,
        documentNumber: this.profile.documentNumber,
        newPassword: undefined,
        newPasswordConfirmation: undefined
      }
    };
  },
  validations: {
    checked: {
      sameAs: sameAs(() => true)
    },
    updatedProfile: {
      givenName: {
        required
      },
      familyName: {
        required: requiredIf(function(updatedProfile) {
          return updatedProfile.documentNumber.length === 11;
        })
      },
      documentNumber:{
        required,
        numeric
      },   
      newPassword: {
        required,
        valid: function(value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        },
        containsNumber: function(value) {
          return /[0-9]/.test(value)
        },
        containsUpperCase: function(value) {
          return /[A-Z]/.test(value)
        },
        containsLowercase: function(value) {
          return /[a-z]/.test(value)
        },
        containsSpecial: function(value) {
          return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)
        },
        minLength: minLength(8)
      },
      newPasswordConfirmation: {
        required,
        sameAsPassword: sameAs('newPassword')
      }
    }    
  },
  created() {
    return this.$v.$touch();
  },
  mounted() {
    if(document.getElementById("cpfCnpj").value.length == 14) {
      document.getElementById("givenNameLabel").innerHTML = "Nome da empresa";
      document.getElementById("cpfCnpjLabel").innerHTML = "CNPJ";
      document.getElementById("cpfCnpj").placeholder = 99999999999999;
      document.getElementById("familyNameGroup").style.display = "none";
    }
  },
  computed: {
    isDisabled() {
      this.$v.$touch();
      if(this.tabIndex === 0) {
        if(this.updatedProfile.givenName !== this.profile.givenName || this.updatedProfile.familyName !== this.profile.familyName || this.updatedProfile.documentNumber !== this.profile.documentNumber) {
          if(this.updatedProfile.documentNumber.length === 11 || this.updatedProfile.documentNumber.length === 14) {
            return (this.$v.updatedProfile.givenName.$invalid || this.$v.updatedProfile.familyName.$invalid || this.$v.updatedProfile.documentNumber.$invalid || this.$v.checked.$invalid);
          }
        }

        // Como os valores são iguais aos recebidos, desativamos o botão.
        return true;
      }

      return this.$v.updatedProfile.newPassword.$invalid || this.$v.updatedProfile.newPasswordConfirmation.$invalid;
    },
  },
  methods:{
    close() {
      this.tabIndex = 0;
      this.setActive(0);
    },
    setActive(tabIndex) {
      if (tabIndex === 0) {
        this.updatedProfile.newPassword = undefined;
        this.updatedProfile.newPasswordConfirmation = undefined;
      } else {
        this.updatedProfile.givenName = this.profile.givenName;
        this.updatedProfile.familyName = this.profile.familyName;
        this.updatedProfile.documentNumber = this.profile.documentNumber;
        this.checked = true;
      }
    },
    closeAndEmit(updatedProfile) {
       this.$emit('update-profile', updatedProfile);
      this.$refs.closeButton.click();
    }
  }
};
</script>